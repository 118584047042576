
<style>
  .margina {
    margin-right: 8px;
  }
  .myButton:disabled {
	color:#ffffff;
  }
  .myButton {
	box-shadow:inset 0px 1px 0px 0px #2479D1;
	background:linear-gradient(to bottom, #2479D1 5%, #2479D1 100%);
	background-color:#2479D1;
	border-radius:6px;
	border:1px solid #2479D1;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:16px;
	font-weight:bold;
	text-decoration:none;
	text-shadow:0px 1px 0px #2479D1;
}
.myButton:hover {
	background:linear-gradient(to bottom, #2479D1 5%, #2479D1 100%);
	background-color:#2479D1;
}
.myButton:active {
	position:relative;
	top:1px;
}
@media (max-width: 576px){
  .myButton {
    width: 100%;
  }
}
</style>

<form action="/api/quote2" [formGroup]="SearchFormGroup" >
<div class="container" style="width: auto;">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-form-field style="width: 100%;">
        <mat-label>Abholort</mat-label> <!--Pickup location-->
        <input (click)="SearchFormGroup.controls['src'].setValue('')" required formControlName="src" matInput placeholder="Ort der Abholung" value="" aria-label="State"
        [matAutocomplete]="autoGroup">
        <mat-autocomplete md-dropdown-items="20" #autoGroup="matAutocomplete">
          <mat-optgroup *ngFor="let city of srcGroupOptions | async" [label]="city.CityName+' , ' +city.Country">
            <mat-option *ngFor="let station of city.stations" [value]="station">
              <img class="example-option-img" aria-hidden [src]="IconST(station)" height="25">
              <span>{{station}}</span>
            </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-checkbox *ngIf="!isShowRuckgabe" color="primary" (change)="isShowRuckgabe = true">Rückgabe an einem anderen Ort?</mat-checkbox>
      <mat-form-field *ngIf="isShowRuckgabe" style="width: 100%;">
        <mat-label>Rückgabe am selben Ort</mat-label> <!--Same as Pickup location-->
        <input (click)="SearchFormGroup.controls['dist'].setValue('')" formControlName="dist" matInput placeholder="Ort der Rückgabe" value="" aria-label="State"
        [matAutocomplete]="distGroup">
        <mat-icon class="clear-icon" (click)="isShowRuckgabe = false">close</mat-icon>
        <mat-autocomplete md-dropdown-items="20" #distGroup="matAutocomplete">
          <mat-optgroup *ngFor="let city of distGroupOptions | async" [label]="city.CityName+' , ' +city.Country">
            <mat-option *ngFor="let station of city.stations" [value]="station">
              <img class="example-option-img" aria-hidden [src]="IconST(station)" height="25">
              <span>{{station}}</span>
            </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-form-field style="width: 100%;" appearance="standard" >
        <mat-label>Datum der Anmietung - Datum der Rückgabe</mat-label>
        <mat-date-range-input  touchUi [min]="minDate" (focus)="picker.open()" (click)="picker.open()" required [rangePicker]="picker">
          <input [readonly]="true" matInput [min]="minDate" matStartDate formControlName="srcDate" >
          <input [readonly]="true" matInput [min]="srcDate" matEndDate formControlName="distDate" >
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="SearchFormGroup.controls.srcDate.hasError('matStartDateInvalid')">Ungültiges Abholdatum</mat-error>
        <mat-error *ngIf="SearchFormGroup.controls.distDate.hasError('matEndDateInvalid')">Ungültiges Rückgabedatum</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-form-field style="width: calc(50% - 4px)" appearance="standard" class="margina">
        <mat-label>Uhrzeit der Anmietung</mat-label> <!--pickup time-->
        <select matNativeControl required [formControl]="srcTimeCtrl">
          <option *ngFor="let food of time" [value]="food">
            {{food}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field style="width: calc(50% - 4px)" appearance="standard">
        <mat-label>Uhrzeit der Rückgabe</mat-label> <!--Return time-->
        <select matNativeControl required [formControl]="DistTimeCtrl">
          <option *ngFor="let food of time" [value]="food">
            {{food}}
          </option>
        </select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 f-l age-live">
    <div class="f-l age">
      <mat-form-field appearance="standard">
        <mat-label>Alter des Fahrers</mat-label> <!--Driver Age-->
        <select matNativeControl required [formControl]="ageCtrl">
          <option *ngFor="let food of age" [value]="food">
            {{food}}
          </option>
        </select>
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 f-l live">
      <mat-checkbox color="primary" (change)="Sel_live=!Sel_live" [checked]="Sel_live">Ich wohne in {{geo}}</mat-checkbox> <!--i live in-->
      <div *ngIf="!Sel_live" [@slideInOut] >
        <mat-select-country  formControlName="country"
                    label="Land auswählen"
                    [itemsLoadSize]="5"
                    (onCountrySelected)="onCountrySelected($event)"
                    [value]="country"
          >
          </mat-select-country>
      </div>
    </div>
    </div>
    <div class="col-lg-12 col-md-12 f-l search-btn">
      <button type="submit" (click)="submit()" class="myButton" mat-button
       >Jetzt Mietwagen finden</button> <!--find now a car-->
    </div>

  </div>
</div>
</form>
