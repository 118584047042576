import { Component, OnInit,ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { trigger, transition, animate, style } from '@angular/animations';
import {FormControl, ValidatorFn, AbstractControl,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DB } from "./DB_DE";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import moment from 'moment';
import { environment } from 'src/environments/environment';

const apiEndpoint = environment.production ? 'https://booking.mietwagenpro.de/':'https://stage.mietwagenpro.de/';

export interface CityGroup {
  id : number;
  CityName: string;
  Country: string;
  Airports:number;
  stations: string[];
  IATAs: string[];
}
export interface City {
  flag: string;
  name: string;
  population: string;
}
export interface Country {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  numericCode: string;
  callingCode:string;
}
export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  if (filterValue.length < 3 || filterValue==='') {
    return [];
}
else {
  return opt.filter((item) => item.toLowerCase().indexOf(filterValue) !== -1);
}
  //return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dd, DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ],
  providers:[{provide: MAT_DATE_LOCALE, useValue: 'de'},
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},],
})
export class AppComponent implements OnInit {
  range_date = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  title = 'SearchForm';
  minDate= moment(new Date()).format('YYYY-MM-DD');;
  srcDate = new Date();
  distDate = new Date();
  Data: CityGroup[]=[];
  CityMapping=[];
  srcGroupOptions!: Observable<CityGroup[]>;
  distGroupOptions!: Observable<CityGroup[]>;

  SearchFormGroup!: FormGroup;
  @ViewChild('Returnpicker') Returnpicker!: MatDatepicker<any>;
  @ViewChild('Pickuppicker') Pickuppicker!: MatDatepicker<any>;
  foods = [10,20];
  country={
    name: 'Deutschland',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    numericCode: '276',
    callingCode: "+49"
  };
  geo="Deutschland";
  Sel_live=true;
  mycountry="DE";
  age=this.range(18,25);
  time=["00:00","00:30","01:00","01:30","02:00","02:30","03:00","03:30","04:00","04:30","05:00","05:30","06:00","06:30","07:00","07:30","08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30","12:00","12:30","13:00","13:30","14:00","14:30","15:00","15:30","16:00","16:30","17:00","17:30","18:00","18:30","19:00","19:30","20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30"];
  isShowRuckgabe = false;
  constructor(private db:DB,private _http: HttpClient,private _formBuilder: FormBuilder) {
    this._http.get("https://api.ipbase.com/v1/json?apikey=LUE4uUDKIi91zCf7W9pEgUQ6H0Tsaj1NauTpxAiF&language=en").subscribe((res:any)=>{
      const matchingCountry:Country = this.db.DE.find((c) => c.alpha2Code == res.country_code);
      this.mycountry=res.country_code;
      this.country=matchingCountry;
      this.geo=matchingCountry.name;
    });
    this.age.push("26 - 63");
    this.age=this.age.concat(this.range(64,99))

    this._http.get(apiEndpoint+"api/cities").subscribe((res:any)=>{

      //console.log(res)
      this.CityMapping=res;
      res.forEach((city:any) => {
        const matchingCountry:Country = this.db.DE.find((c) => c.alpha2Code == city.CountryCode);
        let stations=[];
        let IATAs=[];
        let airports=0;

        if(city.TYPE.indexOf('a')!== -1 && (city.TYPE.indexOf('d')!== -1 || city.TYPE.indexOf('r')!== -1 || city.TYPE.indexOf('h')!== -1) )
          stations.push(city.CityName+" alle Stationen ("+ (city.Region!="" ? city.Region+" / " :"") + matchingCountry?.name +")" )

        if(city.TYPE.indexOf('a')!== -1)
          for (const code of city.IATA.split(':')) {
            stations.push(city.CityName+" Flughafen - "+code+" ("+ (city.Region!="" ? city.Region+" / " :"") + matchingCountry?.name +")" )
            airports+=1;
            IATAs.push(code);
          }

        let types=[];
        if(city.TYPE.indexOf('d')!== -1)
          types.push("Stadt")
        if(city.TYPE.indexOf('r')!== -1)
          types.push("Bahnhof")
        if(city.TYPE.indexOf('h')!== -1)
          types.push("Hafen")

        if(city.TYPE.indexOf('d')!== -1 || city.TYPE.indexOf('r')!== -1 || city.TYPE.indexOf('h')!== -1)
            stations.push(city.CityName+" ("+ types.join(' / ') +") ("+ (city.Region!="" ? city.Region+" / " :"") + matchingCountry?.name +")" )

      this.Data.push({id:city.id,CityName:city.CityName,Country:(city.Region!="" ? city.Region+" / " :"") + matchingCountry?.name,Airports:airports, stations:stations,IATAs:IATAs})
      });
      //this.mycountry=res.country_code;
      //console.log(this.Data)
    });

    }
    ageCtrl=  new FormControl("26 - 63", Validators.required);
    srcTimeCtrl=  new FormControl("12:00", Validators.required);
    DistTimeCtrl=  new FormControl("12:00", Validators.required);

    LocationValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
        let forbidden;
        forbidden=this.Data.findIndex(x => x.stations.findIndex(x=> x==control.value)!=-1);
          //console.log(control.value,forbidden)
        return forbidden==-1 ? {matched: {value: control.value}} : null;
      };
    }
    addEvent(event: MatDatepickerInputEvent<Date>) {
      // change min date for input of return date
        this.srcDate=new Date(event.value!);
        this.distDate=new Date(event.value!);
        this.distDate.setDate(this.distDate.getDate()+7);
        this.SearchFormGroup.controls['distDate'].setValue(this.distDate);
    }
  ngOnInit(): void {

    this.srcDate.setDate(this.srcDate.getDate() + 7);
    this.distDate.setDate(this.distDate.getDate() + 14);
    this.SearchFormGroup = this._formBuilder.group({
      src: ['', [Validators.required,this.LocationValidator()]],
      dist:['', null],
      srcDate:[this.srcDate, [Validators.required,Validators.min(moment(new Date()).millisecond())]],
      srcTime:this.srcTimeCtrl,
      distDate: [this.distDate, [Validators.required,Validators.min(moment(this.srcDate).millisecond())]],
      distTime:this.DistTimeCtrl,
      age:this.ageCtrl,
      country:['', null],
    });
    this.srcGroupOptions = this.SearchFormGroup.get('src')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
    this.distGroupOptions = this.SearchFormGroup.get('dist')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
  }
  private _filterGroup(value: string): CityGroup[] {
    if (value) {
      let res= this.Data
        .map((group:CityGroup) => ({id:group.id, CityName: group.CityName, Country: group.Country, Airports:group.Airports,IATAs:group.IATAs,stations: _filter(group.stations, value)}))
        .filter((group:CityGroup) => group.stations.length > 0).sort((a,b)=> (a.Airports<b.Airports) ? 1 : -1);
      const ifoundIATA=res.findIndex((c) => c.IATAs.findIndex((element) => element.toLowerCase()==value.toLowerCase())!==-1);
      const foundIATA=res.find((c) => c.IATAs.findIndex((element) => element.toLowerCase()==value.toLowerCase())!==-1);
      const found=foundIATA?foundIATA: {
        id : 0,
        CityName: "",
        Country: "",
        Airports:0,
        stations: [],
        IATAs: [],
      };
      if (ifoundIATA>-1){
        res.splice(ifoundIATA, 1);
        res.splice(0,0,found);
      }
      return res.slice(0, 20);
    }
    return [];
  }
  onAirport(station:string){
    //console.log(station.indexOf("Flughafen"))
    return station.indexOf("Flughafen")!==-1;
  }
  IconST(station:string){
    return this.onAirport(station) ? apiEndpoint+"assets/airplane.png" : apiEndpoint+"assets/downtown.png";
  }
  submit(){
    //console.log(this.SearchFormGroup.getRawValue());
    let form=this.SearchFormGroup.getRawValue();
    if (this.Data.findIndex(x => x.stations.findIndex(x=> x==form.src)!=-1)==-1) {
      this.SearchFormGroup.controls['src']!.setErrors({matched: {value: form.src}});
      return;
    }
    if (this.Data.findIndex(x => x.stations.findIndex(x=> x==form.dist)!=-1)==-1 && form.dist!="") {
      this.SearchFormGroup.controls['dist']!.setErrors({matched: {value: form.dist}});
      return;
    }
    const srconA=this.onAirport(form.src)
    const src=srconA ? form.src.slice(0,form.src.indexOf("Flughafen")-1) : form.src.indexOf(" alle Stationen (")!==-1 ? form.src.slice(0,form.src.indexOf(" alle Stationen (")) : form.src.slice(0,form.src.indexOf("(")-1);
    //console.log()
    const srcIATA=srconA ? form.src.split("Flughafen - ")[1].slice(0,3) : "";
    const srcType=srconA ? "A" : form.src.indexOf(" alle Stationen (")!==-1 ? "" : "DR";

    const distonA=this.onAirport(form.dist)
    const dist=this.onAirport(form.dist) ? form.dist.slice(0,form.dist.indexOf("Flughafen")-1) : form.dist.indexOf(" alle Stationen (")!==-1 ? form.dist.slice(0,form.dist.indexOf(" alle Stationen (")) : form.dist.slice(0,form.dist.indexOf("(")-1);
    const distIATA=distonA ? form.dist.split("Flughafen - ")[1].slice(0,3) : "";
    const distType=dist ? (distonA ? "A" : form.dist.indexOf(" alle Stationen (")!==-1 ? "" : "DR") : "";

    let time=form.srcTime.split(':');
    let date=form.srcDate;
    if('_d' in form.srcDate){
      date=form.srcDate._d;
    }
    date.setHours(time[0],time[1],0);
    const tsrc=formatDate(date,"MM/dd/YYYY HH:mm","en_US");
    date=form.distDate;
    if('_d' in form.distDate){
      date=form.distDate._d;
    }
    time=form.distTime.split(':');
    date.setHours(time[0],time[1],0);
    const tdist=formatDate(date,"MM/dd/YYYY HH:mm","en_US");
    let age;
    if(form.age=="26 - 63")
      age=35;
    else age=form.age;
    const country= this.Sel_live? this.country : form.country;
    //console.log(src,dist,tsrc,tdist,age,country);
    const link = apiEndpoint+"api/quote2?src="+src+"&srcIATA="+srcIATA+"&srcType="+srcType+"&dist="+dist+"&distIATA="+distIATA+"&distType="+distType+"&fullsrc="+form.src+"&fulldist="+form.dist+"&tsrc="+tsrc+"&tdist="+tdist+"&age="+age+"&country="+encodeURIComponent(JSON.stringify(country));
    window.location.href=link;
  }
  onChange($event:any){
    //console.log($event.checked);

  }
  onCountrySelected($event: Country) {
    //console.log($event);
  }
  public range (start:number, end:number) {
    let arr:Array<any>=[];
    return arr.concat([...Array(1+end-start).keys()].map(v => start+v));
  }
}
